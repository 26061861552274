const data={
    brand:{
        title:"Deep Blue",
        subTitle:"深圳市深蓝空间科技有限公司",
        code:"91440300MA5H65JF1P",
        desc:`2020年我们在深圳成立，创始人员4人，基于同样的"原生原创"理念，我们联合在一起。
        因对网站开发的热爱，因对当前网站开发服务市场现状的不满，我们成立了"深蓝空间"，我们从不追求数量和低价，
        我们要做的是打造精品项目。开发的过程也是与用户不断沟通的过程，我们邀请用户全程参与，创新采用分段开发、分段计费、分段部署的开发模式，
        在每个开发阶段都保证项目质量与用户需求满足。`,
    },
    items:[
        {
            title:"站内导航",
            subTitle:"Navigation",
            options:[
                {
                    title:"网站",
                    subTitle:"Website",
                    path:"/service/web",
                    newTab:false,
                },
                {
                    title:"微信",
                    subTitle:"Wechat",
                    path:"/service/wechat",
                    newTab:false,
                },
                {
                    title:"电商",
                    subTitle:"E-Commerce",
                    path:"/service/ecommerce",
                    newTab:false,
                },
                {
                    title:"爬虫",
                    subTitle:"Crawler",
                    path:"/service/crawler",
                    newTab:false,
                }
            ]
        },
        {
            title:"云服务商",
            subTitle:"Cloud Providers",
            options:[
                {
                    title:"阿里云",
                    subTitle:"AliCloud",
                    path:"https://www.aliyun.com/",
                    newTab:true,
                },
                {
                    title:"亚马逊云",
                    subTitle:"AWS",
                    path:"https://aws.amazon.com/cn/campaigns/freecenter/",
                    newTab:true,
                },
                {
                    title:"华为云",
                    subTitle:"HuaweiCloud",
                    path:"https://activity.huaweicloud.com/",
                    newTab:true,
                },
                {
                    title:"腾讯云",
                    subTitle:"TencentCloud",
                    path:"https://cloud.tencent.com/",
                    newTab:true,
                }
            ]
        },
        {
            title:"设计素材",
            subTitle:"Cloud Providers",
            options:[
                {
                    title:"花瓣",
                    subTitle:"HuaBan",
                    path:"https://huaban.com",
                    newTab:true,
                },
                {
                    title:"千图",
                    subTitle:"58Pic",
                    path:"https://www.58pic.com/",
                    newTab:true,
                },
                {
                    title:"视觉中国",
                    subTitle:"VCG",
                    path:"https://www.vcg.com/",
                    newTab:true,
                },
                {
                    title:"昵图",
                    subTitle:"NiPic",
                    path:"https://www.nipic.com/",
                    newTab:true,
                }
            ]
        },
        {
            title:"编程框架",
            subTitle:"Programming Frames",
            options:[
                {
                    title:"Bootstrap",
                    subTitle:"CSS",
                    path:"https://v5.bootcss.com/",
                    newTab:true,
                },
                {
                    title:"Vue",
                    subTitle:"Javascript",
                    path:"https://cn.vuejs.org/",
                    newTab:true,
                },
                {
                    title:"Laravel",
                    subTitle:"PHP",
                    path:"https://laravel.com/",
                    newTab:true,
                },
                
                {
                    title:"Mysql",
                    subTitle:"Database",
                    path:"https://www.mysql.com/",
                    newTab:true,
                }
            ]
        }
    ]
}

export default data;