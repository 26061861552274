//head
import head from '@/assets/home/wechat.jpg'
import headVideo from '@/assets/home/head.mp4'
//service
import website from '@/assets/home/service/website.webp'
import wechat from '@/assets/home/service/wechat.jpg'
import eb from '@/assets/home/service/eb.jpg'
import crawler from '@/assets/home/service/crawler.jpg'

//process
import demand from '@/assets/home/process/demand.webp'
import design from '@/assets/home/process/design.png'
import code from '@/assets/home/process/code.jpg'
import test from '@/assets/home/process/test.jpg'
import delivery from '@/assets/home/process/delivery.jpg'

//feature
import professional from '@/assets/home/feature/professional.jpg'
import team from '@/assets/home/feature/team.jpg'
import origin from '@/assets/home/feature/origin.jpg'
import iteration from '@/assets/home/feature/iteration.jpg'

//concat
import concat from '@/assets/home/concat.webp'
import code1 from '@/assets/home/code1.png'
import code2 from '@/assets/home/code1.png'

//message
import message from '@/assets/home/head.jpg'


const homeData={
    head:{
        logo:'logo',
        title:"Design & Progam",
        subTitle:"深蓝空间",
        desc:"Deep Blue Netwrok",
        img:head,
        video:headVideo,
        btns:[
            {value:"menu",label:"Menu"}
        ]
    },
    slogan:{
        title:'Original',
        subTitle:'原生代码 原创设计',
        desc:'因不满中国当下糟糕的（粗制滥造、套版抄袭、恶意低价）网络开发服务市场环境。<br/>深蓝空间科技有限公司于2020年成立，秉承"原创设计 原生代码"的服务理念。<br/>立志于为社会提供基于原创、标准流程开发、追求友好用户体验的网络产品与服务。',
    },
    service:[
        {
            title:"网站",
            subTitle:"Website",
            children:["多屏合一","手机网站","微信网站","电脑网站"],
            image:website,
            path:"/service/web",
            color:'#7F96FF',
            icon:'laptop',
        },
        {
            title:"微信",
            subTitle:"Wechat",
            children:["小程序","公众号","微信支付","商务服务"],
            image:wechat,
            path:"/service/wechat",
            color:'#A6CFD5',
            icon:'chat',
        },
        {
            title:"电子商务",
            subTitle:"E-Commerce",
            children:["电商运营系统","仓库管理系统","电商引流站点","自营电商平台",],
            image:eb,
            path:"/service/ecommerce",
            color:'#E56399',
            icon:'cart2',
        },
        {
            title:"网络爬虫",
            subTitle:"Crawler",
            children:["电商爬虫","金融爬虫","新闻爬虫","社交爬虫","全能爬虫"],
            image:crawler,
            path:"/service/crawler",
            color:'#DBFCFF',
            icon:'bug',
        }
    ],
    process:{
        header:{
            title:"标准开发流程",
            subTitle:"Development Process",
            desc:"无论项目大小，我们一律采用业界标准软件开发流程，保证每个环节都让用户参与，确保开发质量。",
        },
        items:[
            {
                class:"square-a",
                classMobile:"square-a",
                title:"需求分析",
                subTitle:"Demand Analysis",
                desc:"搞清用户设想，将理念落地形成需求文档和原型图",
                img:demand,
            },
            {
                class:"square-a",
                classMobile:"square-b",
                title:"界面设计",
                subTitle:"Page Design",
                desc:"搞清用户设想，将理念落地形成需求文档和原型图",
                img:design,
            },
            {
                class:"square-b",
                classMobile:"square-c",
                title:"编程开发",
                subTitle:"Programming",
                desc:"搞清用户设想，将理念落地形成需求文档和原型图",
                img:code
            },
            {
                class:"square-c",
                classMobile:"square-b",
                title:"测试与优化",
                subTitle:"Testing and Optimization",
                desc:"搞清用户设想，将理念落地形成需求文档和原型图",
                img:test,
            },
            {
                class:"square-a",
                classMobile:"square-a",
                title:"封版交付",
                subTitle:"Hand Over",
                desc:"搞清用户设想，将理念落地形成需求文档和原型图",
                img:delivery,
            }
        ]
    },
    cases:{
        header:{
            title:"案例",
            subTitle:"Cases",
            desc:"网站 | 电商 | 爬虫",
        },
        items:[
            {
                img:head,
                tag:"网站",
                name:"案例一",
                account:"user / password",
            },
            {
                img:head,
                tag:"网站",
                name:"案例一",
                account:"user / password",
            },
            {
                img:head,
                tag:"网站",
                name:"案例一",
                account:"user / password",
            },
            {
                img:head,
                tag:"网站",
                name:"案例一",
                account:"user / password",
            },
            {
                img:head,
                tag:"网站",
                name:"案例一",
                account:"user / password",
            }
        ]
    },
    feature:{
        header:{
            title:"专业团队与管理",
            subTitle:"Features & Strengths",
            desc:"基于原创理念和标准开发流程，我们着重打造高质量的团队，项目开发的效果最终取决于团队的专业性和先进的开发模式。",
        },
        items:[
            {
                img:professional,
                title:"资深雇员",
                subTitle:"Experienced Employees",
                options:[
                    "互联网/科技/金融从业经历",
                    "五年以上开发/设计/管理经验",
                    "长期担任团队领导角色"
                ],
            },
            {
                img:team,
                title:"专项团队",
                subTitle:"Project Team",
                options:[
                    "按项目专门组建团队",
                    "5~8人，灵活且高效",
                    "跟踪项目从分析到交付"
                ],
            },
            {
                img:origin,
                title:"原生原创",
                subTitle:"Original Design",
                options:[
                    "不使用模板网站",
                    "不套用陈旧框架",
                    "按需求设计 按设计开发"
                ],
            },
            
            {
                img:iteration,
                title:"敏捷开发",
                subTitle:"Fast Iterative",
                options:[
                    "清晰的需求分解与计划",
                    "按周开发/测试/迭代",
                    "支持每周需求变更"
                ],
            },
        ]
    },
    concat:{
        title:"联系我们",
        subTitle:"Contact US",
        avatar:concat,
        info:[
            {
                name:"小周",
                phone:"13040800634",
                email:"1771548541@qq.com",
                qq:"1771548541",
            },
            {
                name:"小雪",
                phone:"13480970114",
                email:"1607468135@qq.com",
                qq:"1607468135",
                
            },
        ],
        qrcode:[
            {
                name:"小周",
                code:code1,
            },
            {
                name:"小雪",
                code:code2,
            },
        ]
    },
    message:{
        title:"在线留言",
        subTitle:"Online Message",
        img:message,
        placeholder:{
            name:"姓名",
            concat:"邮箱/手机/微信",
            message:"写下您的诉求...",
        },
        btn:"提交",
    },
}

export default homeData;